<template>
  <ChiTiet />
</template>

<script>
import ChiTiet from '@/modules/quan-ly-quy-trinh/components/pages/QuanLyLuongXuLy/ChiTietQuyTrinh.vue'

export default {
  name: 'TrangXemHoSo',
  components: {
    ChiTiet,
  },
}
</script>

<style scoped></style>
