<template>
  <section>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="card-body">
        <b-row class="mt-2">
          <b-col
            cols="12"
            md="3"
            xl="2"
          >
            <div
              data-title="Phiên bản"
              class="border-title h-100"
            >
              <div class="item-list-pb ui list font-weight-bold">
                <div
                  v-for="item in arrSchema"
                  :key="item.id"
                  class="item"
                  :class="versionNow === item.version ? 'active' : ''"
                  @click="chooseVersion(item)"
                >
                  Phiên bản: {{ item.version }}
                  <svg
                    v-if="item.version == detail.activeVersion"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    class="bi bi-pin-angle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z" />
                  </svg>
                </div>
              </div>
              <span />
            </div>
          </b-col>
          <b-col
            id="wkf-id"
            cols="12"
            md="9"
            xl="10"
          >
            <div
              data-title="Thiết kế"
              class="border-title"
            >
              <form
                id="uploadForm"
                method="post"
                enctype="multipart/form-data"
                @submit="tmp"
              >
                <div class="d-sm-flex justify-content-between mb-1">
                  <div class="d-flex">
                    <div class="small ui right labeled input d-flex">
                      <input
                        type="text"
                        placeholder="Phiên bản"
                        disabled="true"
                        :value="`${versionStr} ${versionNow}`"
                      >
                      <div class="ui basic">
                        <button
                          v-b-tooltip.hover.v-secondary
                          title="Lưu"
                          class="compact ui icon btn-primary button"
                          type="button"
                          @click="onSave"
                        >
                          <!-- <save-icon
                            size="14"
                            class="custom-class"
                          /> -->
                          <i class="save outline icon custom-class" />
                        </button>
                      </div>
                    </div>
                    <button
                      class="compact ui btn-primary button"
                      type="button"
                      @click="onUse"
                    >
                      Sử dụng
                    </button>
                    <button
                      class="compact ui btn-primary button"
                      type="button"
                      @click="addScheme"
                    >
                      <plus-icon
                        size="12"
                        class="custom-class"
                        :stroke-width="3"
                      /> Phiên bản mới
                    </button>
                  </div>
                  <div class="mt-1-500">
                    <button
                      v-b-tooltip.hover.v-secondary
                      title="Làm lại"
                      class="compact ui icon secondary button"
                      type="button"
                      @click="onNew"
                    >
                      <refresh-cw-icon
                        size="13"
                        class="custom-class"
                        :stroke-width="3"
                      />
                    </button>
                    <button
                      class="compact ui btn-primary button"
                      type="button"
                      @click="downloadScheme"
                    >
                      Tải về XML
                    </button>
                    <button
                      class="compact ui secondary button"
                      type="button"
                      @click="selectScheme"
                    >
                      Tải lên XML
                    </button>
                    <button
                      id="full-screen-icon"
                      v-b-tooltip.hover.v-secondary
                      title="Phóng to"
                      class="compact ui icon secondary button"
                      type="button"
                      @click="onFullScreen"
                    >
                      <i class="expand icon" />
                    </button>
                  </div>
                  <input
                    id="uploadFile"
                    type="file"
                    class="d-none"
                    name="uploadFile"
                    @change="onUploadScheme"
                  >
                </div>
                <div
                  id="wfdesigner"
                  class="overflow-hidden"
                />
              </form>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>
<script>
import { DOMAIN, API_VERSION } from '@/constants/constants'
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import $ from 'jquery'
import {
  RefreshCwIcon,
  // SaveIcon,
  PlusIcon,
} from 'vue-feather-icons'

export default {
  name: 'TrangDanhSachForm',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    RefreshCwIcon,
    // SaveIcon,
    PlusIcon,
  },
  data() {
    return {
      graphwidth: null,
      graphheight: null,
      wfdesigner: null,
      schemecode: null,
      processid: null,
      versionStr: 'Phiên bản',
      payload: {
        filter: {
          code: null,
          sort: '-Version',
        },
      },
      arrSchema: null,
      currentSchema: null,
      versionNow: '',
    }
  },
  async created() {
    await this.loadScripts()
    this.getDetailWorkflow()
    if (this.$route.query.tthcId) {
      this.onFullScreen()
    }
  },
  methods: {
    getDetailWorkflow() {
      this.$axios.get(`${END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.CHI_TIET}/${this.$route.params.id}`).then(res => {
        if (res.data?.code === 200) {
          this.detail = res.data.data
          this.payload.filter.code = this.detail.code
          this.getAllWorkflow()
        }
      })
    },
    createWorkflow() {
      this.graphwidth = $(window).width()
      const graphminheight = $(window).height()
      this.graphheight = graphminheight
      this.wfdesigner = undefined
      const result = this.getQueryString()
      // this.schemecode = result.schemecode
      this.schemecode = `${this.detail.code}_${this.versionNow}`
      if (this.schemecode === undefined || this.schemecode === '') {
        this.schemecode = 'TestScheme'
      }
      this.processid = result.processid
      $(window).resize(() => {
        if (window.wfResizeTimer) {
          clearTimeout(window.wfResizeTimer)
          window.wfResizeTimer = undefined
        }
        window.wfResizeTimer = setTimeout(() => {
          if (this.graphheight < this.graphminheight) this.graphheight = this.graphminheight
          this.wfdesignerRedraw()
        }, 150)
      })
      $(window).resize()
    },
    getAllWorkflow() {
      this.$axios.get(END_POINTS.WORKFLOW_SCHEME_VERSION.ALL, this.payload).then(res => {
        if (res.data?.code === 200) {
          this.arrSchema = res.data.data
          this.versionNow = this.versionNow || this.arrSchema[0].version
          // eslint-disable-next-line prefer-destructuring
          this.currentSchema = this.arrSchema[0]
          this.createWorkflow()
        }
      })
    },
    postSaveWorkflowVersion() {
      if (this.currentSchema?.id) {
        this.$toast.success('Lưu luồng xử lý thành công')
        return
      }
      this.currentSchema = {
        ...this.currentSchema,
        code: this.detail.code,
        version: this.versionNow,
        scheme: `${this.detail.code}_${this.versionNow}`,
        isActive: false,
      }
      this.$axios.post(`${END_POINTS.WORKFLOW_SCHEME_VERSION.THEM}`, this.currentSchema, false).then(res => {
        if (res.data?.code === 200) {
          this.$axios.get(END_POINTS.WORKFLOW_SCHEME_VERSION.ALL, this.payload).then(result => {
            if (result.data?.code === 200) {
              this.arrSchema = result.data.data
              this.versionNow = this.arrSchema[0].version
            }
          })
          this.$toast.success(res.data.message)
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    addScheme() {
      this.currentSchema = null
      this.versionNow = this.arrSchema.length + 1
      this.schemecode = `${this.detail.code}_${this.versionNow}`
      this.wfdesignerRedraw(true)
    },
    async wfdesignerRedraw(isdestroydata = false) {
      let data
      await this.$nextTick()
      if (this.wfdesigner !== undefined) {
        data = this.wfdesigner.data
        this.wfdesigner.destroy()
      }
      if (isdestroydata) {
        data = undefined
      }
      // eslint-disable-next-line no-undef
      this.wfdesigner = new WorkflowDesigner({
        name: 'simpledesigner',
        apiurl: `${DOMAIN}${API_VERSION}${END_POINTS.WORKFLOW_SCHEME_DESIGNERAPI.API_URL}`,
        renderTo: 'wfdesigner',
        imagefolder: '/assets/workflow/images/',
        graphwidth: this.graphwidth,
        graphheight: this.graphheight,
      })
      if (data === undefined) {
        let isreadonly = false
        if (this.processid !== undefined && this.processid !== '') {
          isreadonly = true
        }
        const p = { schemecode: this.schemecode, processid: this.processid, readonly: isreadonly }
        if (this.wfdesigner.exists(p)) {
          this.wfdesigner.load(p)
        } else {
          this.wfdesigner.create()
        }
      } else {
        this.wfdesigner.data = data
        this.wfdesigner.render()
      }
    },
    getQueryString() {
      // eslint-disable-next-line prefer-const
      let queryString = {}
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        // If first entry with this name
        if (typeof queryString[pair[0]] === 'undefined') {
          // eslint-disable-next-line prefer-destructuring
          queryString[pair[0]] = pair[1]
        } else if (typeof queryString[pair[0]] === 'string') {
          const arr = [queryString[pair[0]], pair[1]]
          queryString[pair[0]] = arr
        } else {
          queryString[pair[0]].push(pair[1])
        }
      }
      return queryString
    },
    async loadScripts() {
      this.renderExternalScript('/assets/workflow/js/jquery.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/semantic.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/konva.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/ace.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/jquery.auto-complete.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/workflowdesigner.js').onload = () => {}
      this.renderExternalLink('/assets/workflow/css/workflowdesigner.css').onload = () => {}
      this.renderExternalLink('/assets/workflow/css/semantic.min.css').onload = () => {}
    },
    async renderExternalLink(href) {
      const link = document.createElement('link')
      link.type = 'text/css'
      link.href = href
      link.rel = 'stylesheet'
      await document.body.appendChild(link)
      return link
    },
    async renderExternalScript(src) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.async = true
      script.defer = true
      await document.body.appendChild(script)
      return script
    },
    onNew() {
      this.wfdesigner.create()
    },
    onSave(e) {
      e.preventDefault()
      this.wfdesigner.schemecode = this.schemecode
      const err = this.wfdesigner.validate()
      if (err !== undefined && err.length > 0) {
        this.$toast.error(err)
      } else {
        this.wfdesigner.save(() => {
          this.postSaveWorkflowVersion()
        })
      }
    },
    downloadScheme() {
      this.wfdesigner.downloadscheme()
    },
    selectScheme() {
      const file = $('#uploadFile')
      file.trigger('click')
    },
    onUploadScheme() {
      let dem = 0
      this.wfdesigner.uploadscheme($('#uploadForm')[0], () => {
        if (dem === 0) {
          this.$toast.success('Tải file thành công')
          dem = 1
        }
      })
    },
    chooseVersion(item) {
      this.currentSchema = item
      this.versionNow = item.version
      this.schemecode = `${this.detail.code}_${this.versionNow}`
      this.wfdesignerRedraw(true)
    },
    onUse() {
      this.detail.activeVersion = this.currentSchema.version
      this.currentSchema.status = true
      this.$axios.put(`${END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.SUA}/${this.detail.id}`, this.detail, false).then(res => {
        if (res.data?.code === 200) {
          this.getDetailWorkflow()
        }
      })
    },
    onFullScreen() {
      $('#uploadForm').toggleClass('fullscreen')
      if ($('#uploadForm').hasClass('fullscreen')) {
        this.graphheight = window.innerHeight
        this.graphwidth = window.innerWidth
        $('#full-screen-icon').html('<i class="compress icon"></i>')
      } else {
        this.graphheight = 400
        $('#full-screen-icon').html('<i class="expand icon"></i>')
      }
      this.wfdesignerRedraw()
    },
    tmp() {

    },
  },
}
</script>

<style scoped lang="scss">
  * {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif
  }
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .border-title {
    border: 1.5px solid #EEEEEE;
    padding: 15px 10px;
    position: relative;
    &:before{
      content: attr(data-title);
      position: absolute;
      top: -10px;
      background: #fff;
      color: #333333;
      font-weight: 700;
      padding: 0 5px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .bg-ee {
    background: #EEEEEE;
  }
  .cursor-pinter {
    cursor: pointer;
  }
  .item-list-pb .item {
    padding: 10px 5px !important;
    font-weight: 600;
    &:hover {
      @extend .bg-ee;
      @extend .cursor-pinter;
    }
  }
  .item-list-pb .item.active {
    @extend .bg-ee;
    @extend .cursor-pinter;
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }
</style>
